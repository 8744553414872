const { OfferTypes, MENUID_DOMAIN } = require('../constants/variables');
const {
  clevertapEventPush,
  CT_EVENT_IDS,
} = require('../helpers/clevertapUtil');

exports.validateResponse = (response) => {
  if (response.status === 204) {
    return;
  }
  if (!response.ok) {
    throw response;
  }
  return response.json();
};

exports.ImageResizeUrl = (url, width, height) => {
  let updatedUrl = '';
  if (url.includes('w_100,h_100,c_fill')) {
    updatedUrl = url.replace(
      'w_100,h_100,c_fill',
      `w_${width},h_${height},c_fill`
    );
  } else {
    updatedUrl = url.replace(
      '/upload/',
      `/upload/w_${width},h_${height},c_fill/`
    );
  }
  return updatedUrl;
};
exports.validAlphanumericString = (value) => {
  if (!value) {
    return true;
  } else {
    var regex = /^[0-9a-zA-Z]+$/;
    if (value.match(regex)) {
      return true;
    } else {
      return false;
    }
  }
};

exports.range = require('lodash/range');
exports.get = require('lodash/get')

exports.offersMessage = (
  discount_type,
  max_discount,
  min_order_value,
  code,
  discount_value,
  custom
) => {
  if (discount_type && min_order_value && code && !custom) {
    let description = '';
    switch (discount_type) {
      case OfferTypes.FLAT:
        if (discount_value) {
          description = `Use code ${code} and get ₹${discount_value} off on all the orders above ₹${min_order_value} `;
        }
        break;
      case OfferTypes.PERCENT:
        if (max_discount && discount_value) {
          description = `Use code ${code} and get ${discount_value}% off on all orders above ₹${min_order_value} upto ₹${max_discount} `;
        }
        break;
      case OfferTypes.FREEDELIVERY:
        if (max_discount) {
          description = `Use code ${code} and get free delivery on all the orders above ₹${min_order_value} upto ₹${max_discount} `;
        }
        break;
      default:
        break;
    }
    return description;
  }
};

exports.getMinDate = () => {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  today = yyyy + '-' + mm + '-' + dd;
  return today;
};

exports.getFormattedDate = (value) => {
  var today = new Date(value);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  today = yyyy + '-' + mm + '-' + dd;
  return today;
};

exports.specialCharactersRestriction = (value) => {
  if (
    value.includes('*') ||
    value.includes('[') ||
    value.includes(']') ||
    value.includes('+') ||
    value.includes('?') ||
    value.includes('(') ||
    value.includes(')') ||
    value.includes('<') ||
    value.includes('>') ||
    value.includes('\\') ||
    value.includes('{') ||
    value.includes('}') ||
    value.includes('.') ||
    value.includes(',') ||
    value.includes('/') ||
    value.includes(' ') ||
    value.includes('~') ||
    value.includes('!') ||
    value.includes('@') ||
    value.includes('#') ||
    value.includes('$') ||
    value.includes('%') ||
    value.includes('^') ||
    value.includes('&') ||
    value.includes(':') ||
    value.includes(';') ||
    value.includes('`') ||
    value.includes("'") ||
    value.includes('"') ||
    value.includes('|')
  ) {
    return true;
  }
};

exports.charactersRestriction = (value) => {
  const regex = /[A-Za-z]/g;
  if (value.match(regex)) {
    return true;
  } else {
    return false;
  }
};

exports.emailRestriction = (value) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
};

exports.phoneValidation = (value) => {
  var phoneNum = value.replace(/[^\d]/g, '');
  if (phoneNum.length > 6 && phoneNum.length < 11) {
    return true;
  }
};

exports.integersRestriction = (value) => {
  if (
    value.includes('*') ||
    value[0] === ' ' ||
    value.includes('<') ||
    value.includes('>') ||
    value.includes("'") ||
    value.includes('[') ||
    value.includes(']') ||
    value.includes('+') ||
    value.includes('?') ||
    value.includes('(') ||
    value.includes(')') ||
    value.includes('\\') ||
    value.includes('`') ||
    value.includes('{') ||
    value.includes('}') ||
    value.includes('.') ||
    value.includes(',') ||
    value.includes('/') ||
    value.includes('~') ||
    value.includes('!') ||
    value.includes('@') ||
    value.includes('#') ||
    value.includes('$') ||
    value.includes('%') ||
    value.includes('^') ||
    value.includes('&') ||
    value.includes(':') ||
    value.includes(';') ||
    value.includes('"') ||
    value.includes('|') ||
    value.includes('0') ||
    value.includes('1') ||
    value.includes('2') ||
    value.includes('3') ||
    value.includes('4') ||
    value.includes('5') ||
    value.includes('6') ||
    value.includes('7') ||
    value.includes('8') ||
    value.includes('9') ||
    value.includes('-') ||
    value.includes('=') ||
    value.includes('_')
  ) {
    return true;
  }
};

exports.getDDMMYYYY = (value) => {
  var today = new Date(value);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  today = dd + '-' + mm + '-' + yyyy;
  return today;
};

exports.convHHMMtoAMPM = (value) => {
  var hrs = value.split(':')[0];
  var mins = value.split(':')[1];
  var median = 'AM';
  if (hrs >= 12) {
    median = 'PM';
  }
  if (hrs > 12) {
    hrs -= 12;
  }
  var AmPm = hrs + ':' + mins + ' ' + median;
  return AmPm;
};

exports.isMobileOrTablet = () => {
  return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
};

exports.isIPhone = () => {
  return /(iphone|ipad)/i.test(navigator.userAgent);
};

exports.isMobile = () => {
  return /(android|iphone|mobile)/i.test(navigator.userAgent);
};

exports.isBroswerSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

exports.focusOnField = (id, isFocused = true) => {
  const field = document.getElementById(id);
  if (field) {
    field.scrollIntoView({ behavior: 'instant', block: 'center' });
    isFocused && field.focus();
  }
};

exports.trimmedValue = (val) => {
  if (val) {
    return val.trim();
  }
  return '';
};

exports.darkenColor = (col, gradientDegree = 40) => {
  let usePound = false;
  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }
  const num = parseInt(col, 16);
  let r = (num >> 16) + gradientDegree;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  let b = ((num >> 8) & 0x00ff) + gradientDegree;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  let g = (num & 0x0000ff) + gradientDegree;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

exports.pushHistory = (
  history,
  routeWithMenuId,
  routeWithoutMenuId,
  menuId,
  searchParam,
  state
) => {
  if (menuId === MENUID_DOMAIN) {
    history.push({
      pathname: routeWithoutMenuId,
      search: searchParam ? searchParam : '',
      state,
    });
  } else {
    history.push({
      pathname: routeWithMenuId.replace(':productId', menuId),
      search: searchParam ? searchParam : '',
      state,
    });
  }
};

exports.detectOS = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows";
  }
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  // iOS detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
  return "unknown";
};

exports.isWindowOnFocus = () => {
  // Standard browsers:
  var hidden = "hidden",
    visibilityChange = "visibilitychange";
  if (typeof document?.hidden !== "undefined") {
    hidden = "hidden";
    visibilityChange = "visibilitychange";
  } else if (typeof document?.mozHidden !== "undefined") {
    hidden = "mozHidden";
    visibilityChange = "mozvisibilitychange";
  } else if (typeof document?.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
  } else if (typeof document?.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
  } else if ("onfocusin" in document)
    document.onfocusin = document.onfocusout = onchange;
  //IOS devices and others
  else
    window.onpageshow =
      window.onpagehide =
      window.onfocus =
      window.onblur =
        onchange;
  var isInFocus = false;
  var onchange = function (evt) {
    var v = "visible",
      h = "hidden",
      evtMap = {
        focus: v,
        focusin: v,
        pageshow: v,
        blur: h,
        focusout: h,
        pagehide: h,
      };
    evt = evt || window.event;
    if (evt.type in evtMap)
      h === evtMap[evt.type] ? (isInFocus = false) : (isInFocus = true);
    document.removeEventListener(visibilityChange, onchange, false);
  };
  document.addEventListener(visibilityChange, onchange, false);
  // set the initial state (but only if browser supports the Page Visibility API)
  if (document[hidden] !== undefined)
    onchange({ type: document[hidden] ? "blur" : "focus" });
  return isInFocus;
};


exports.cleverTapEventAdd = (product, prodDetails) => {
  let eventObj = {
    'Product Name': product?.title,
    ProductId: product?.ref_id,
    Category: prodDetails && prodDetails?.title,
    Subcategory:
      prodDetails &&
      prodDetails?.subcategories[product?.sub_category_id]?.title,
    'Product Selling Price': product?.price,
    'Product MRP': product?.mrp_price ? product?.mrp_price : product?.price,
    'Variant/Unit': product?.unit,
    'Part of Deal': product?.deal_id ? true : false,
  };
  clevertapEventPush({
    eventId: CT_EVENT_IDS.ADDED_TO_CART,
    eventData: eventObj,
  });
};
