import { offersMessage } from '../../utils/common';

export const getDescription = (offer) => {
  let desc = offersMessage(
    offer.discount_type,
    offer.max_discount,
    offer.min_order_value,
    offer.code,
    offer.discount_value,
    false
  );
  return desc;
};
